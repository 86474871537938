import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import "firebase/compat/auth";
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCIPkVqwe8ebKTLPZ461clmTHlArhtaHo0",
  authDomain: "jetwaycouriersvc.firebaseapp.com",
  projectId: "jetwaycouriersvc",
  storageBucket: "jetwaycouriersvc.appspot.com",
  messagingSenderId: "521378297374",
  appId: "1:521378297374:web:c0129677a1e3fa6550600f",
  measurementId: "G-1Q0Z10PXKZ"
};


const app = firebase.initializeApp(firebaseConfig)

export const db = app.firestore()

export  const auth = app.auth()

export const storage = getStorage(app)

export const Provider = new firebase.auth.GoogleAuthProvider()
