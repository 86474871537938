import React from 'react'
import './banner.css'
import ban from './img/ban.mp4'
import ReactPlayer from 'react-player';

function Banners() {
  return (  
    <div  className='banvid'>
      <video className='vid' src={ban} autoPlay muted loop  />
     
     <div className='gif'>
      <ReactPlayer
          url={ban}
          playing={true} 
          loop={true} 
          controls={false}
          width='100%'
          height='100%'
        />
     </div>
    </div> 
  )
}

export default Banners