import React from 'react'
import {Page, Text, Image, Document, StyleSheet, View, PDFViewer} from '@react-pdf/renderer'
import logo from './img/log.png'
import ban from './img/ban.png'
import bar from './img/bar.png'
import stmp from './img/smtp.jpg'
import py from './img/py.jpg'
import { selectInfo } from './features/infoSlice'
import { useSelector } from 'react-redux'


const styles = StyleSheet.create({
  view:{
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    height:'100vh'
  },  
  body:{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  main:{
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth:'600px',
  },
  header:{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  fst_writeups:{
    width: '100%',
    display: 'flex',
    flexDirection:'row',
    fontSize:'10px',
    fontWeight:'800',
    marginVertical: 10,
    justifyContent: 'center',
  },
  imag:{
    width:'50%'
  },
  imag2:{
    width:'50%'
  },
  header_bod:{
    width:'50%',
    display:'flex',
    flexDirection:'column',
    alignItems: 'center',
  },
  header_subbod:{
    width:'100%',
    justifyContent:'space-between',
    display:'flex',
    flexDirection:'row',
    marginVertical: 40,
  },
  mid_text:{
    display:'flex',
    width:'100%',
    textAlign:'center',
  },
  mid:{
    width:'100%',
    display:'flex',
    flexDirection:'row',
    marginVertical:40,
  },
  mid_box:{
    width: '30%',
    fontSize:'15px',
    marginHorizontal : 20,
  },
  mid_boxsub:{
    marginVertical:20,
    width:'100%'
  },
  mid_boxbar:{
    marginVertical:10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  min:{
    marginRight: 200,
  },
  copy:{
    transform : 'rotate(-45deg)',
    fontSize:'30px'
  },
  mid2:{
    width:'100%',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
  },
  mid2_box:{
    fontSize:'9px',
    marginHorizontal: 10
  },
  tit:{
    fontSize:'12px',
  },
  titt:{
    fontSize:'9px',
  },
  name:{
    color:'orange',
    fontWeight:800,
  },
  bottom:{
    width:'100%',
    display:'flex',
    flexDirection:'row'
  },
  bottombox:{
    width:'50%',
    Margin:10,
    display:'flex',
    alignItems: 'center',
    marginTop : 30
  },
  bottomimg1:{
    width:'100%',
  },
  bottomimg2:{
    width:'40%',
  }
})

const Pdffile=()=>{

  const info = useSelector(selectInfo)

       return(
        <div>
          <PDFViewer fileName='cargoxpress.pdf' style={styles.view}>
          <Document>
        <Page style={styles.body}>

        <View style={styles.main}>
          <View style={styles.header}>

            <View style={styles.fst_writeups}>
              <Text style={styles.min} >{info?.tracker.time}</Text>
              <Text>JetWay | Invoice</Text>
            </View>

            <View style={styles.header_subbod}>
              <View style={styles.header_bod}>
                <Image style={styles.imag} src={logo} />
                <Text style={styles.name}>JetWay Service</Text>
                <Text>Tracking Number : {info?.tracker.trackid}</Text>
              </View>

              <Image style={styles.imag2} src={ban} />
            </View>

          </View>


          <View style={styles.mid_text}>
          <Text style={styles.copy}>Certified True Copy</Text>
          <Text>JetWay Service</Text>
          <Text>Address : USA, CANADA, UK, ASIA & EUROPE</Text>
          <Text>Mail : support@jetwaycouriercompany.com</Text>
          <Text>Company Website : https://jetwaycouriercompany.com</Text>
          </View>


          <View style={styles.mid}>
            <View style={styles.mid_box}>
              <Text style={styles.tit}>FROM(SENDER)</Text>
              <Text style={styles.mid_boxsub}>{info?.tracker.sendersname}</Text>
              <Text style={styles.titt}>Address : {info?.tracker.senderslocation}</Text>
              <Text style={styles.titt}>Origin Office : JetWay Service</Text>
            </View>

            <View style={styles.mid_box}>
              <Text style={styles.tit}>TO(CONSIGNEE)</Text>
              <Text style={styles.mid_boxsub}>{info?.tracker.recieversname}</Text>
              <Text style={styles.titt}>Phone : {info?.tracker.phonenumber} </Text>
              <Text style={styles.titt}>Address : {info?.tracker.recieverslocation}</Text>
            </View>

            <View style={styles.mid_box}>
              <View style={styles.mid_boxbar}>
                <Image style={styles.imag} src={bar} />
                <Text style={styles.titt}>{info?.tracker.trackid}</Text>
              </View>
              <Text style={styles.titt}>Orde ID : 809 </Text>
              <Text style={styles.titt}>Payment Due : {info?.tracker.time} </Text>
              <Text style={styles.titt}>Insurance of the shipment : $0.00 </Text>
            </View>
          </View>



          <View style={styles.mid2}>
            <View style={styles.mid2_box}>
              <Text>Qty</Text>
              <Text>{info?.tracker.quantity}</Text>
            </View>
            <View style={styles.mid2_box}>
              <Text>Product</Text>
              <Text>{info?.tracker.packagename}</Text>
            </View>
            <View style={styles.mid2_box}>
              <Text>Status</Text>
              <Text>{info?.tracker.status}</Text>
            </View>
            <View style={styles.mid2_box}>
              <Text>Description</Text>
              <Text>Payment before Delivery</Text>
            </View>
            <View style={styles.mid2_box}>
              <Text>Shipping Cost</Text>
              <Text>$ {info?.tracker.amount}</Text>
            </View>
            <View style={styles.mid2_box}>
              <Text>Clearance Cost</Text>
              <Text>-$ {info?.tracker.amount}</Text>
            </View>
            <View style={styles.mid2_box}>
              <Text>Total Cost</Text>
              <Text>-$ 0.00</Text>
            </View>
          </View>


          <View style={styles.bottom}>
            <View style={styles.bottombox}>
              <Text>Payment Methods: </Text>
              <Image style={styles.bottomimg1} src={py} /> 
            </View>

            <View style={styles.bottombox}>
              <Text>Official stamp / {new Date(info?.tracker.timestamp?.toDate()).toLocaleDateString()} : </Text>
              <Image style={styles.bottomimg2} src={stmp} /> 
              <Text style={styles.titt}>Stamp Duty:</Text>
            </View>
          </View>



          </View>
        </Page>
      </Document>
          </PDFViewer>
        </div>
       )
}

export default Pdffile