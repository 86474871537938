import React from 'react'
import Header from './Header'
import Bottom from './Bottom'
import './contact.css'
import { Email, Phone } from '@mui/icons-material'

function Contact() {

  const handlesubmit=()=>{
    
  }

  return (
    <div className='contact'>
      <Header/>
      <div className='contacthd'>
        <h3>Contact us</h3>
        <div className='abtshd'></div>
      </div>

      <div className='contact_body'>

        <div className='comp'>
          <h1>Head Office</h1>

          <h3>Location</h3>
          <p>
              HQ OFFICE: Senate house Washington DC United States
              Consultant Office: 237 4 Ave SW, calgary, AB T2P 4K3, Canada
          </p>

          <h3>Contact info</h3>
          <p>  support@jetwaycouriercompany.com</p>
          <p>  +1 (771) 220‑***</p>
          <p></p>
        </div>


        <input placeholder='Full name'/>
        <input placeholder='Email Address'/>
        <input placeholder='Phone number'/>
        <textarea placeholder='write a message' />
        <button onClick={handlesubmit} >SUBMIT</button>
      </div>

      <Bottom/>
    </div>
  )
}

export default Contact