import React from 'react'
import Pdffile from './Pdffile'
import { useSelector } from 'react-redux'
import { selectInfo } from './features/infoSlice'
import { Link } from 'react-router-dom'
import  secu from './img/secu.png'

function Pdfviewer(){

  const info = useSelector(selectInfo)

    return(
      <>
        {
        info?.tracker ?
        <Pdffile/>
        :
          <div className='track_bod2'>
          <img className='secu' src={secu} alt='' />
            <h3>Can't find track id</h3>
            <p>This happened for security reason </p>
            <small>please go back and reload the page thank you</small>
            <p>Go back 
              <Link to='/'>
                <p>/Home</p>
              </Link>
            </p>
        </div>
      }
      </>
    )
}

export default Pdfviewer