import React from 'react'
import './map.css'
import { LocationOn } from '@mui/icons-material'

function Map() {
  return (
   <div className='mab_bod'>
        <h2>COUNTRIES COVERED</h2>
        <div className='map1'>
            <h3>North AMERICA</h3>
            <strong>cover 20+ Countries</strong>
            <LocationOn/>
        </div>
        <div className='map2'>
            <h3>SOUTH AFRICA</h3>
            <strong>cover 10+ Countries</strong>
            <LocationOn/>
        </div>
        <div className='map3'>
            <h3>EUROPE</h3>
            <strong>cover 25+ Countries</strong>
            <LocationOn/>
        </div>
        <div className='map4'>
            <h3>ASIA</h3>
            <strong>cover 22+ Countries</strong>
            <LocationOn/>
        </div>
        <div className='map5'>
            <h3>AFRICA</h3>
            <strong>cover 10+ Countries</strong>
            <LocationOn/>
        </div>
        <div className='map6'>
            <h3>OCEANIA</h3>
            <strong>cover 5+ Countries</strong>
            <LocationOn/>
        </div>
   </div>
  )
}

export default Map