import React from 'react'
import { useSelector } from 'react-redux'
import { selectInfo } from './features/infoSlice'
import Header from './Header';
import {Adjust, CheckCircle, Error, Flight, FlightLand, FlightTakeoff, Home, Remove } from '@mui/icons-material';
import './popup.css'
import { Link } from 'react-router-dom';
import secu from './img/secu.png'



function Popups() {

    const info = useSelector(selectInfo)

  return (
    <div className='track'>
      <Header/>

      {
        info?.tracker != null?
        <div className='track_bod1'>
        <div className='track_bod'>
        <div className='track_head'>
          <div className='track_sub'>
            <h3>INVOICE</h3>
            <strong>#809</strong>
          </div>

          <div className='trach_subhead'>
            <div className='track_sub'>
              <p>Expected Arrival</p>
              <strong>{info?.tracker.time}</strong>
            </div>

            <div className='track_sub'>
              <p>Tracking No.</p>
              <strong>{info?.tracker.trackid}</strong>
            </div>
          </div>
        </div>


        <div className='flip'>
                <div className='flip_icon'>
                {
                    info?.tracker.fst ?
                    <CheckCircle id='icons'/>
                    :
                    <Remove id='iconc'/>
                }
                </div>

                <div className='box'>

                    {
                      info?.tracker.sec ?
                        <div className='flip_icon2' >
                            <Remove id='icon1'/>
                            <CheckCircle id='icon'/>
                        </div>
                        :
                        <div className='flip_icon' >
                            <Remove id='icon1'/>
                            <CheckCircle id='iconc'/>
                        </div>
                    }

                    
                </div>

                <div className='box'>
                    {
                      info?.tracker.trd?
                        <div className='flip_icon2'>
                            <Remove id='icon1'/>
                            <CheckCircle id='icon'/>
                        </div>
                        :
                        <div className='flip_icon'>
                            <Remove id='icon1'/>
                            <CheckCircle id='iconc'/>
                        </div>
                    }
                </div>

                <div className='box'>
                    {
                      info?.tracker.frt?
                        <div className='flip_icon2'>
                            <Remove id='icon1'/>
                            <Adjust id='icon'/>
                        </div>
                        :
                        <div className='flip_icon' >
                            <Remove id='icon1'/>
                            <Adjust id='iconc'/>
                        </div>
                    }
                </div>


            </div>


        <div className='flip2'>
            <div className='flip_icon'>
                {
                    !info?.tracker.fst?
                    <div className='sail'>
                      <Error id='icon'/>
                      <small>shipped</small>
                    </div>
                    :
                    <div className='sail'>
                    <FlightTakeoff id='icon'/>
                    <small>confirmed</small>
                    </div>
                }
            </div>

            <div className='flip_icon'>
                {
                  info?.tracker.sec?
                   <div className='sail'>
                    <Flight id='icon'/>
                    <small>shipped</small>
                   </div>
                    :
                    <div className='sail'>
                      <Error id='icon'/>
                      <small>pending</small>
                    </div>
                }
            </div>

            <div className='flip_icon'>
                {
                  info?.tracker.trd?
                    <div className='sail'>
                    <FlightLand id='icon'/>
                    <small>sailing</small>
                    </div>
                    :
                    <div className='sail'>
                      <Error id='icon'/>
                      <small>pending</small>
                    </div>
                }
            </div>

            <div className='flip_icon'>
                {
                  info?.tracker.frt?
                    <div className='sail'>
                      <Home id='icon'/>
                      <small>delivered</small>
                    </div>
                    :
                    <div className='sail'>
                      <Error id='icon'/>
                      <small>pending</small>
                    </div>
                }    
            </div>
        </div>
        <p style={{color:'white', margin:'10px'}}>Processed Shipped En Route Arrived</p>
        </div>
        

        <div className='label'>
          <h3>STATUS: {info?.tracker.status}</h3>
        </div> 


        <div className='other_details'>
          <div className='detail_box'>
              <h3>SHIPMENT DETAILS</h3>
              <div className='lit'>
                <p>Qauntity : <span>{info?.tracker.quantity}</span></p>
                <p>Service Type : <span>{info?.tracker.servicetype}</span> </p>
                <p>Weight : <span>{info?.tracker.packageweight}</span></p>
                <p>Description : <span>{info?.tracker.packagename}</span></p>
              </div>
          </div>


          <div className='detail_box'>
              <h3>DESTINATION</h3>
              <div className='lit'>
                <p>Receiver Name : <span>{info?.tracker.recieversname}</span></p>
                <p>Receiver Email :  <span>{info?.tracker.recieversemail}</span></p>
                <p>Receiver Address : <span>{info?.tracker.recieverslocation}</span></p>
                <p>Expected Date of Delivery : <span>{info?.tracker.time}</span></p>
              </div>
          </div>


          <div className='detail_box'>
              <h3>ORIGIN</h3>
              <div className='lit'>
                <p>Sender Name : <span>{info?.tracker.sendersname}</span></p>
                <p>Sender Email : <span>{info?.tracker.sendersemail}</span></p>
                <p>Senders Address : <span>{info?.tracker.senderslocation}</span></p>
                <p>Location : <span>{info?.tracker.companylocation}</span> </p>
                <p>Expected Date of Delivery : <span>{info?.tracker.time}</span></p>
                <p>shipment Date : {info?.tracker.shippeddate}</p>
              </div>
          </div>
          
        </div>

        { info?.tracker.img ?
          <div className='packageimg'> 
            <img src={info?.tracker.img} alt='' />
          </div>
          :
          null
        }


        <Link to='/pdffile' className='request rec'>
          <p>View Receipt</p>
        </Link>
      

        </div>


        
        :
          <div className='null'>
          <div className='track_bod2'>
            <img className='secu' src={secu} alt='' />
            <h3>Can't find track id</h3>
            <p>This happened for security reason </p>
            <small>please go back and reload the page thank you</small>
            <p>Go back 
              <Link to='/'>
                <p>/Home</p>
              </Link>
            </p>
          </div>
          </div>
      }

    </div>
  )
}

export default Popups