import React, { useState } from 'react'
import { addDoc, collection } from 'firebase/firestore';
import { db } from './firebase';

function Bottom() {


  const [email, setemail] = useState('')

  const add=async()=>{
    try{
      await addDoc(collection(db, 'chat'),{
        email : email
      })
    }catch(e){
      console.log(e.message);
    }
  }
 
  return (
    <div className='reach'>
      <div className='bttmsh'> </div>
      <div className='reach_body'>
        <h3>Jet Way</h3>
        <p>Yes Senders was established to cater for domestic 
        and commercial shipping world wide with our 
        headquartered in and Branches all over the world.</p>

        <p>For enquiries, send mails to</p>
        <small>‎support@jetwaycouriercompany.com</small>

        <h4>You can also contact us at</h4>
        <p>HQ OFFICE: Senate house Washington DC United States
            Consultant Office: 237 4 Ave SW, calgary, AB T2P 4K3, Canada</p>
      </div>

      <div className='reach_body'>
        <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
        <input onChange={((e)=>setemail(e.target.value))} placeholder='examples@gmaol.com'/>
        <span onClick={add}>Subscribe</span>
      </div>
    </div>
  )
}

export default Bottom